
import { Action, Getter } from "vuex-class";
import { Component, Ref, Vue } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit } from "@/components/forms";
import { formatErrors } from "@/utils/formatters";
import { bus } from "@/main";
import CreateAppointmentModal from "./create-appointment.vue";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        AppointmentLocationPart: () => import("@/components/modals/parts/appointment-location.vue"),
        FormBase,
        FormError,
        FormSubmit,
    },
})
export default class UpdateAppointmentModal extends CreateAppointmentModal {
    @Action("appointment/update") updateAppointment!: (payload: { payload: AppointmentCreatePayload; id: number }) => void;

    appointment: Appointment | null = null;

    appointment_id: number | null = null;

    show = false;

    errorResponse: ErrorResponse = {
        status: 0,
        errors: [],
    };

    type: "update" | "create" | null = "update";

    initOpenClose() {
        bus.$off("show-update-appointment");
        bus.$on("show-update-appointment", (appointment: Appointment) => {
            this.loadAdminMetaData();

            this.loadPartnerMetaData();

            this.show = true;
            this.appointment = appointment;
            this.appointment_id = appointment.id;
            this.payload.guests = this.appointment.guests ?? [];
            this.payload.invited_by = this.appointment.invited_by;
            this.payload.action_id = this.actionOptions.find((ao) => ao.value === appointment.action_id)?.value || null;
            this.payload.planned_at = appointment.planned_at;
            this.payload.duration = appointment.duration;
            this.payload.contacts = appointment.contacts;
            this.payload.title = appointment.title;
            this.payload.description = appointment.description;
            this.payload.activity_id = appointment.activity_id;
            this.payload.location = appointment.location;

            this.maybeChangeDescription();
        });
    }

    async submit(form: FormClass) {
        if (!this.appointment_id) {
            return;
        }

        try {
            await this.updateAppointment({ payload: this.payload, id: this.appointment_id });

            this.$toast.open({ message: this.$t("views.appointments.update.success") as string, type: "success", position: "bottom-right" });

            this.handleClose();
        } catch (e) {
            this.errorResponse = formatErrors(e);
        } finally {
            form.reset();
        }
    }

    handleClose() {
        this.errorResponse = {
            status: 0,
            errors: [],
        };

        this.show = false;
    }
}
